html,body{
    height:100%;
    width:100%;
    margin:0;
    background: url(../assets/main-sportsbook.jpg);
    background-size: cover;
    background-position-x: 36%;
}

.home {
    .form-container{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        .form-wrap{
            display: flex;
            flex-direction: column;
            align-items: center;
            .fd-logo { 
                width: 200px; 
            }
            form.opt-out-form {
                max-width: 600px;
                background: white;
                padding: 20px;
                border-radius: 5px;
                button {
                    &.btn-primary {
                        &.fd{
                            background-color: #0060CC;
                            margin-bottom: 0 !important;
                            padding: 0.8em;
                        }
                        &.disabled {
                            background-color:rgb(245, 248, 252);
                            border-color: rgb(207, 214, 219);
                            color: #99A8B1;
                        }
                    }
                }
                .fd-input {
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    padding: 0 0.5em;
                    transition: all 0.4s ease;
                    label, .invalid-feedback {
                        font-size: 0.7em;
                        text-transform: uppercase !important;
                        font-family: "Roboto Condensed";
                        font-weight: 400;
                        letter-spacing: 0.1em;
                    }
                    input {
                        border: none;
                        padding: 0.5em;
                        &:focus {
                            outline: 0px !important;
                            box-shadow: none;
                        }
                    }
                    &.dd {
                        border: none;
                        padding: 0;
                        margin-bottom: 1em;
                    }
                    &.invalid{
                        border-color:#ff4136 !important;
                        label, .invalid-feedback {
                            color: #ff4136 !important;
                        }
                        .invalid-feedback{
                            letter-spacing: 0;
                        }
                    }
                    &.active{
                        border-color:#0060CC;
                        label {
                            color: #0060CC;
                        }
                    }
                }
                .fd-info {
                    text-align: center;
                    font-size: 0.8em;
                    padding-top: 20px;
                }
                .dropdown-menu {
                    max-height: 300px;
                    overflow: auto;
                    min-width: 362px;
                }
            }
            .card-header {
                text-align: center;
                background-color: white;
                h3 {
                    color: #1F375B;
                    font-size: 20px;
                    margin: 0;
                    padding: 5px;
                }
            }
        }
        .success {
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 400px;
            text-align: center;
            h3 {
                color: #189F4A;
            }
            img {
                height: 100px;
                margin: 20px;
            }
        }
        .error {
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 400px;
            text-align: center;
            h3 {
                color: #ca0d0d;
            }
            img {
                height: 100px;
                margin: 20px;
            }
        }
        .captcha {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        @media screen and (max-width: 500px) {
            .card {
                border-radius: 0;
            }
        }
    }
}
